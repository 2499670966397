import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGet, useAuth } from 'hooks';
import { getListsForUser, getOrgListsForUser } from 'services/api';
import { getActiveList, getSelectedMembers } from 'selectors/lists';
import { setModal, setAddToTarget } from 'actions/lists';
import { Button, Tooltip, Select } from 'components/common';
import { LABEL_BUTTON_ADD_NEW_LIST } from 'components/common/labelConstants';
import licenseType from 'models/licenseType';

import { ActionsContainer } from './styled';
import { accessLevels } from '../helpers';
import { views } from '../constants';
import listRenderOption from 'components/common/Lists/ListRenderOptions';

const AddToList = ({
  disabled,
  onCreateNewItemList,
  view = views.researchers,
}) => {
  const dispatch = useDispatch();
  const activeList = useSelector(getActiveList);
  const activeListRef = useRef();
  const [{ res: editableLists, loading }, getEditableLists] = useGet({
    url: view === views.researchers ? getListsForUser : getOrgListsForUser,
    query: { onlyEditable: true },
  });

  useEffect(() => {
    if (activeList && activeList.id) {
      if (activeListRef.current && activeListRef.current.id === activeList.id) {
        return;
      }

      getEditableLists();
      activeListRef.current = activeList;
    }
  }, [activeList, getEditableLists]);

  const handleAddTo = id => {
    if (id === 'no_lists') return;
    const targetList = editableLists.find(l => l.id === id);

    dispatch(setAddToTarget(targetList));
    dispatch(setModal('addTo'));
  };

  const addToListOptions =
    editableLists && editableLists.length > 0
      ? editableLists
          .filter(l => l.id !== activeList?.id)
          .map(l => ({
            label: l.name,
            value: l.id,
            description: l.description,
          }))
      : [{ label: 'No lists available', value: 'no_lists' }];

  if (addToListOptions.length === 0) {
    addToListOptions.push({ label: 'No lists available', value: 'no_lists' });
  }
  const isDisabled = disabled || loading;

  return (
    <Select
      showSearch
      size='small'
      placeholder='Add to List'
      value='Add to List'
      onCreateNewItem={onCreateNewItemList}
      buttonText={LABEL_BUTTON_ADD_NEW_LIST}
      options={addToListOptions}
      style={{ width: 150 }}
      renderOption={(o, i) => listRenderOption({ ...o }, i)}
      dropdownStyle={{ minWidth: 300 }}
      filterOption={(input, option) =>
        option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={isDisabled}
      onChange={handleAddTo}
    />
  );
};

const Actions = ({
  totalMembers,
  disabled,
  loading,
  onCreateNewItemList,
  view = views.researchers,
}) => {
  const dispatch = useDispatch();
  const activeList = useSelector(getActiveList);
  const selectedMembers = useSelector(getSelectedMembers);
  const { user, hasLicense } = useAuth();
  let selectedMembersCount = 0;

  if (selectedMembers.all) {
    selectedMembersCount =
      totalMembers - Object.keys(selectedMembers.exclude).length;
  } else {
    selectedMembersCount = Object.keys(selectedMembers.include).length;
  }

  const handleClick = modal => {
    dispatch(setModal(modal));
  };

  const canModify =
    (accessLevels.modifyMembers.includes(activeList.userAccessLevel) ||
      user.isAccountManager) &&
    activeList.enabled;
  const canPush =
    accessLevels.push.includes(activeList.userAccessLevel) ||
    user.isAccountManager;
  const hasCrmIntegration = user.canPushToSalesforce || user.canPushToHubspot;

  const hasPermissionToPushExport =
    hasLicense(
      [
        licenseType.temporaryOld,
        licenseType.legacy,
        licenseType.academic,
        licenseType.industry,
      ],
      false
    ) && !user.isRateLimited;

  const showTotalMembers = totalMembers > 0 && !loading;
  const disableCRMPush =
    disabled ||
    !showTotalMembers ||
    !hasCrmIntegration ||
    !hasPermissionToPushExport ||
    !canPush ||
    (!user.isHubspotAuthenticated && !user.isSalesforceAuthenticated);

  return (
    <ActionsContainer>
      {hasPermissionToPushExport && (
        <Button
          disabled={disabled || !showTotalMembers}
          size='small'
          onClick={() => handleClick('export')}
        >
          Export
        </Button>
      )}

      {view === views.researchers && (
        <Tooltip
          title={
            !canPush && 'You do not have permission to perform this action'
          }
        >
          <div>
            <Button
              disabled={disableCRMPush}
              size='small'
              onClick={() => handleClick('pushCRM')}
            >
              Push to CRM
            </Button>
          </div>
        </Tooltip>
      )}
      <Tooltip
        title={
          !canModify && 'You do not have permission to perform this action'
        }
      >
        <div>
          <Button
            disabled={disabled || !canModify || !showTotalMembers}
            size='small'
            onClick={() => handleClick('removeFrom')}
          >
            Remove Members
          </Button>
        </div>
      </Tooltip>
      <AddToList
        disabled={disabled}
        onCreateNewItemList={onCreateNewItemList}
        view={view}
      />
      {showTotalMembers && (
        <div style={{ fontSize: 13 }}>
          {selectedMembersCount} of {totalMembers} members selected
        </div>
      )}
      <Tooltip
        title={!canPush && 'You do not have permission to perform this action'}
      ></Tooltip>
    </ActionsContainer>
  );
};

export default Actions;
